
import { Vue, Component } from "vue-property-decorator";
import { InvitationObjectDataSource } from "@/data/Object/InvitationObjectDataSource";

@Component
export default class Home extends Vue {
  $message: any;
  loading: boolean = false;
  dataSource: InvitationObjectDataSource = new InvitationObjectDataSource(
    +this.$route.params.id,
    {}
  );

  get getInviteCaption() {
    switch (this.dataSource.model.instanceStateId) {
      case 200:
        return "Пригласить повторно";
      case 1:
        return "Пригласить";
      default:
        return "";
    }
  }

  get caption() {
    return this.dataSource.model?.fullName;
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Приглашения",
        to: "/manage/invitations/",
        exact: true,
      },
      {
        text: this.caption,
        disabled: true,
      },
    ];
  }

  async invite() {
    this.loading = true;
    await this.dataSource.invite();
    this.loading = false;
    this.$message(
      `Приглашение успешно отправлено на почту ${this.dataSource.model?.email}`
    );
  }
}
