import axios from "axios";
import { IBaseConfig } from "@/ioc/types";
import { ObjectDataSource } from "./ObjecDatatSource";

export class InvitationObjectDataSource extends ObjectDataSource {
  constructor(id: number, config: IBaseConfig) {
    super({
      id,
      className: "invitation",
      config,
    });
  }

  public async invite() {
    try {
      await axios.post(`${this.baseUrl}/invite`);
    } catch (error) {
      throw error;
    }
  }
}
